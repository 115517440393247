import { ArrowRight } from "@phosphor-icons/react";
import Emoji from "components/Emoji";
import DashboardPage from "components/layouts/DashboardPage";
import { Link } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import checkIsCapitalAccountSummaryWithChargeCard from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import colors from "styles/colors";
import Teaser from "ui/data-display/Teaser";
import Button from "ui/inputs/Button";
import Heading2 from "ui/typography/Heading2";
import Text from "ui/typography/Text";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";

import CapitalFeatureOverviewTable from "../../components/CapitalFeatureOverviewTable";
import { NotAllowedToActivateLineOfCreditContent } from "../../constants";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

import ChargeCardSummaryItems from "./ChargeCardSummaryItems";
import LineOfCreditSummaryItems from "./LineOfCreditSummaryItems";
import styles from "./OfferedCapitalView.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const OfferedCapitalView: React.FC<Props> = ({ capitalAccountSummary }) => {
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();

  return (
    <>
      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummary} />

      <DashboardPage.Section>
        <Teaser className={styles.teaser}>
          <Teaser.MainSection className={styles.teaser}>
            <Emoji className={styles.emoji}>🎉</Emoji>
            <div className={styles.teaserText}>
              <Heading2>Your {capitalAccountSummary.name} is ready!</Heading2>
              <Text size={16} color={colors.grey[500]}>
                Accept your offer to get started!
              </Text>
            </div>

            {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
              <ChargeCardSummaryItems capitalAccountSummaryWithChargeCard={capitalAccountSummary} />
            ) : (
              <LineOfCreditSummaryItems
                capitalAccountSummaryCashAccessOnly={capitalAccountSummary}
              />
            )}

            <Link to={`/capital/${capitalAccountSummary.guid}/review-line-offer/how-it-works`}>
              <Button
                className={styles.teaserButton}
                variant="primary"
                disabled={!isAllowedToAcceptLineOfCredit}
                tooltip={
                  !isAllowedToAcceptLineOfCredit && <NotAllowedToActivateLineOfCreditContent />
                }
              >
                <div className={styles.acceptButton}>
                  <Text size={14}>Review and accept</Text>
                  <ArrowRight size={14} />
                </div>
              </Button>
            </Link>
          </Teaser.MainSection>
        </Teaser>
      </DashboardPage.Section>

      {capitalAccountSummary.type !== CapitalAccountRep.Type.ChargeCardOnly && (
        <DashboardPage.Section>
          <CapitalFeatureOverviewTable />
        </DashboardPage.Section>
      )}
    </>
  );
};

export default OfferedCapitalView;
