import classNames from "classnames";
import { FC, ReactElement, ReactNode, cloneElement } from "react";
import Text from "ui/typography/Text";

import styles from "./InputWrapper.module.scss";

/**
 * 🚧 Under construction, but feel free to use!
 */

export type InputVariant = "default" | "minimal";

export type SharedInputProps = {
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  showErrorOutline?: boolean;
  variant?: InputVariant;
};

type Props = SharedInputProps & {
  children: ReactElement<{ className?: string }>;
  showFocusOutline: boolean;
  shouldShrinkLabel: boolean;
  inputId?: string;
};

const InputWrapper: FC<Props> = ({
  children,
  shouldShrinkLabel,
  showErrorOutline,
  showFocusOutline,
  inputId,
  startAdornment,
  endAdornment,
  disabled,
  label,
  variant = "default",
  className,
}) => {
  // We don't show the normal label in minimal variant.
  const showLabel = Boolean(label) && variant !== "minimal";
  const showPrefix = startAdornment && (!showLabel || shouldShrinkLabel);

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.shrinkLabel]: shouldShrinkLabel,
        [styles.disabled]: disabled,
        [styles.errorOutline]: showErrorOutline,
        [styles.focusOutline]: showFocusOutline,
        [styles["variant-minimal"]]: variant === "minimal",
      })}
    >
      {showLabel && (
        <label className={styles.label} htmlFor={inputId}>
          {label}
        </label>
      )}
      {/* For minimal variants, still render an invisible label for screen readers */}
      {variant === "minimal" && Boolean(label) && (
        <label className="sr-only" htmlFor={inputId}>
          {label}
        </label>
      )}

      {showPrefix && (
        <Text weight="medium" className={styles.prefix} size={14}>
          {startAdornment}
        </Text>
      )}

      {cloneElement(children, {
        className: classNames(children.props.className, styles.input),
      })}

      {endAdornment && (
        <Text weight="medium" className={styles.suffix} size={12}>
          {endAdornment}
        </Text>
      )}
    </div>
  );
};

export default InputWrapper;
