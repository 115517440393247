import { StatementsListParams } from "@highbeam/unit-node-sdk";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

const unitCoStatementsQueryHooks = makeQueryHooks({
  name: "unitCoStatements",
  useQueryVariables: (params: StatementsListParams) => {
    const bankAccountUnitIds = useBankAccounts({
      status: "all",
      select: (bankAccounts) =>
        bankAccounts.map((bankAccount) => bankAccount.unitCoDepositAccountId),
    });

    const defaults: Partial<StatementsListParams> = {
      sort: "-period",
      accountIds: bankAccountUnitIds,
    };

    return {
      ...defaults,
      ...params,
    };
  },
  useQueryFnMaker: (variables) => {
    const unitApi = useUnitApi();

    return () => {
      return unitApi.statements.list(variables);
    };
  },
});

export default unitCoStatementsQueryHooks;
