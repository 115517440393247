import HighbeamCard from "components/HighbeamCard";
import MetadataList from "components/MetadataList";
import { FC } from "react";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import useBankAccountByUnitCoDepositAccountId from "resources/bank-accounts/queries/useBankAccountByUnitCoDepositAccountId";
import CapitalAccountLabel from "resources/capital-accounts/components/CapitalAccountLabel";
import useCapitalAccountSummary from "resources/capital-accounts/queries/useCapitalAccountSummary";
import useCard from "resources/cards/queries/useCard";
import useCardLimits from "resources/cards/queries/useCardLimits";
import { checkIsCreditCard, CreditCard, DebitCard } from "resources/cards/utils/typeguards";
import useChargeCardAccountByUnitCoCreditAccountId from "resources/charge-cards/queries/useChargeCardAccountByUnitCoCreditAccountId";
import colors from "styles/colors";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import { getCardBillingAddress, getCardholderName } from "utils/cards";

import styles from "./CreateCardModalCardCreatedSuccessfully.module.scss";

type CreateCardModalCardCreatedSuccessfullyHeaderProps = {
  cardId: string;
};

export const CreateCardModalCardCreatedSuccessfullyHeader: FC<
  CreateCardModalCardCreatedSuccessfullyHeaderProps
> = ({ cardId }) => {
  const card = useCard({ cardId, required: true });

  return (
    <div className={styles.header}>
      <Text as="h4" size={16} weight="bold" color={colors.grey[800]} lineHeight={24}>
        Here are the card details.
      </Text>
      <Text as="p" size={14} weight="regular" color={colors.grey[600]} lineHeight={20}>
        {getCardholderName(card)} can now access and use this virtual card from the{" "}
        <strong>Cards</strong> page in their Highbeam account.
      </Text>
    </div>
  );
};

const ChargeCardAccountCapitalAccountLabel: FC<{ creditCard: CreditCard }> = ({ creditCard }) => {
  const chargeCardAccount = useChargeCardAccountByUnitCoCreditAccountId({
    unitCoCreditAccountId: creditCard.relationships.account.data.id,
    required: true,
  });

  const capitalAccountSummary = useCapitalAccountSummary(chargeCardAccount.capitalAccountGuid, {
    required: true,
  });

  return <CapitalAccountLabel capitalAccount={capitalAccountSummary} />;
};

const DebitCardBankAccountLabel: FC<{ debitCard: DebitCard }> = ({ debitCard }) => {
  const bankAccount = useBankAccountByUnitCoDepositAccountId(
    debitCard.relationships.account.data.id,
    { required: true }
  );

  return (
    <BankAccountV2
      bankIcon={<HighbeamAccountIcon highbeamTypeName={bankAccount.highbeamType.name} />}
      accountName={bankAccount.name}
      accountNumber={bankAccount.accountNumber}
    />
  );
};

type Props = {
  cardId: string;
};

const CreateCardModalCardCreatedSuccessfully: FC<Props> = ({ cardId }) => {
  const card = useCard({ cardId, required: true });
  const billingAddress = getCardBillingAddress(card);
  const cardLimits = useCardLimits({ cardId: cardId });

  return (
    <>
      <ModalV4.Body>
        <div className={styles.cardContainer}>
          <HighbeamCard card={card} areDetailsAvailable containerClickable />
        </div>

        <MetadataList className={styles.metadataList}>
          {billingAddress && (
            <MetadataList.Item label="Billing address">
              <Text size={14} weight="medium">
                {billingAddress.street}
              </Text>
              {billingAddress.street2 && (
                <Text size={14} weight="medium">
                  {billingAddress.street2}
                </Text>
              )}
              <Text size={14} weight="medium">
                {billingAddress.city}, {billingAddress.state} {billingAddress.postalCode}
              </Text>
            </MetadataList.Item>
          )}

          <MetadataList.Item label="Associated account">
            {checkIsCreditCard(card) ? (
              <ChargeCardAccountCapitalAccountLabel creditCard={card} />
            ) : (
              <DebitCardBankAccountLabel debitCard={card} />
            )}
          </MetadataList.Item>

          {cardLimits.attributes.limits?.monthlyPurchase && (
            <MetadataList.Item label="Monthly spend limit">
              <MoneyAmount
                cents={cardLimits.attributes.limits.monthlyPurchase}
                size={14}
                weight="medium"
              />
            </MetadataList.Item>
          )}
          {cardLimits.attributes.limits?.dailyPurchase && (
            <MetadataList.Item label="Daily spend limit">
              <MoneyAmount
                cents={cardLimits.attributes.limits.dailyPurchase}
                size={14}
                weight="medium"
              />
            </MetadataList.Item>
          )}
        </MetadataList>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.Footer.CloseButton />
      </ModalV4.Footer>
    </>
  );
};

export default CreateCardModalCardCreatedSuccessfully;
