import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import checkIsCapitalAccountSummaryWithChargeCard, {
  CapitalAccountSummaryWithChargeCard,
} from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import { StatementCycleExplanation } from "resources/charge-card-offer/constants/repayment-periods";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import styles from "./AgreementPlainLanguageSummary.module.scss";

type ChargeCardCapitalAccountPlainLanguageSummaryProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardCapitalAccountPlainLanguageSummary: FC<
  ChargeCardCapitalAccountPlainLanguageSummaryProps
> = ({ capitalAccountSummaryWithChargeCard }) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <Text size={14} color={colors.grey[500]}>
      <ul className={styles.agreementSummaryList}>
        <li>
          Your card limit will start at{" "}
          <MoneyAmount
            cents={capitalAccountSummaryWithChargeCard.details.limit}
            withCents={false}
          />
          .
        </li>

        <li>
          <StatementCycleExplanation chargeCardOffer={chargeCardOffer} />
        </li>
        <li>
          Within 45 days of activation, Highbeam must act as your primary operating account. i.e.
          sales payouts, payroll, inventory, marketing and card payments must flow through Highbeam.
        </li>
        <li>
          No new debt can be taken on without Highbeam approval. Taking on new debt without approval
          will cause the line to be paused.
        </li>
        <li>
          Highbeam will ask for financials from time to time, at least once a quarter, and should be
          submitted within two weeks upon request.
        </li>
      </ul>
    </Text>
  );
};

type CashAccessPlainLanguageSummaryProps = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const CashAccessPlainLanguageSummary: FC<CashAccessPlainLanguageSummaryProps> = ({
  capitalAccountSummaryWithCashAccessOnly,
}) => {
  return (
    <Text size={14} color={colors.grey[500]}>
      <ul className={styles.agreementSummaryList}>
        <li>
          Your line limit will start at{" "}
          <MoneyAmount
            cents={capitalAccountSummaryWithCashAccessOnly.details.limit}
            withCents={false}
          />{" "}
          with a {capitalAccountSummaryWithCashAccessOnly.details.apr * 100}% APR.
        </li>
        <li>
          Please use Highbeam as primary operating account. That is, sales, payroll, inventory,
          marketing and cards must flow through your Highbeam bank accounts.
        </li>
        <li>You have to tell Highbeam if you are considering taking on additional financing.</li>
        <li>
          Highbeam will ask for financials from time to time, at least once a quarter. You will have
          two weeks upon request to provide those.
        </li>
      </ul>
    </Text>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AgreementPlainLanguageSummary: FC<Props> = ({ capitalAccountSummary }) => (
  <div className={styles.agreementSummaryContent}>
    {checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
      <ChargeCardCapitalAccountPlainLanguageSummary
        capitalAccountSummaryWithChargeCard={capitalAccountSummary}
      />
    ) : (
      <CashAccessPlainLanguageSummary
        capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary}
      />
    )}
  </div>
);

export default AgreementPlainLanguageSummary;
