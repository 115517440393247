import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import { BankingPartner } from "resources/bank-accounts/types";
import filterBankAccountsByPartnerBank from "resources/bank-accounts/utils/filterBankAccountsByPartnerBank";

import cardsQueryHooks from "../queries/cardsQueryHooks";

const useCardsByPartnerBank = (bankingPartner: BankingPartner) => {
  const filteredBankAccounts = useBankAccounts({
    status: "all",
    select: (bankAccounts) => filterBankAccountsByPartnerBank(bankAccounts, bankingPartner),
  });

  const filteredBankAccountIds = filteredBankAccounts.map(
    (bankAccount) => bankAccount.unitCoDepositAccountId
  );

  return cardsQueryHooks.useSuspenseQueryData({
    status: "default",
    select: (cards) => {
      return cards.filter((card) => {
        return filteredBankAccountIds.includes(card.relationships.account.data.id);
      });
    },
  });
};

export default useCardsByPartnerBank;
