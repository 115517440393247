import { CardStatus } from "@highbeam/unit-node-sdk";
import { useMemo } from "react";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useHasPermission from "utils/permissions/useHasPermission";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

// NB(alex): `makeQueryHooks` is still extremely experimental and may be brittle.

export const BASE_CARDS_QUERY_NAME = "cards";

type Params = { status: "default" | "closed" } | { statuses: CardStatus[] };

const cardsQueryHooks = makeQueryHooks({
  name: BASE_CARDS_QUERY_NAME,
  useQueryVariables(params: Params) {
    const businessGuid = useBusinessGuid();
    const userGuid = useUserGuid();
    const hasCardReadOwnPermission = useHasPermission("card:readOwn");
    const isSuperusering = useIsSuperusering();

    const status: CardStatus[] = useMemo(() => {
      if ("statuses" in params) {
        return params.statuses;
      }
      switch (params.status) {
        case "default":
          return ["Inactive", "Active", "Frozen", "SuspectedFraud"];
        case "closed":
          return ["ClosedByCustomer"];
      }
    }, [params]);

    return { businessGuid, status, userGuid, hasCardReadOwnPermission, isSuperusering };
  },
  useQueryFnMaker(variables) {
    const { businessGuid, status, userGuid, hasCardReadOwnPermission, isSuperusering } = variables;
    const highbeamApi = useHighbeamApi();
    const unitApi = useUnitApi();

    return async () => {
      // NB(alex): Unit token scoping bug workaround.
      // When an employee user has a token with scoped cards, but the list of scoped card ids is empty, Unit fails to restrict the list of cards.
      // But after fetching the list of cards, the scoped token _does_ work for fetching individual cards, thus throwing a 403.
      // This temporary workaround hits our cards through backend endpoint, and if the returned list of cards is empty, we return an empty array.
      // BUT we disable it for superuser because the endpoint isn't working for superuser.
      // We should fix this soon, but we want to ship employee user role, so this will work as a stop-gap for now.
      // Unit support request slack thread: https://highbeamco.slack.com/archives/C02JB5M1599/p1712866431406719
      if (!isSuperusering && hasCardReadOwnPermission) {
        const { unitResponse: cardsThroughBackend } = await highbeamApi.card.getAll({
          businessGuid: businessGuid,
          userGuid: userGuid,
          status: status,
        });

        if (cardsThroughBackend.length === 0) {
          return [];
        }
      }

      const { data: cards } = await unitApi.cards.list({
        include: "account",
        status: status,
      });

      return cards;
    };
  },
});

export default cardsQueryHooks;
