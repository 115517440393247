import { Warning } from "@phosphor-icons/react";
import classNames from "classnames";
import DashboardPage from "components/layouts/DashboardPage";
import { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import getCapitalAccountAvailablePercentage from "resources/capital-accounts/utils/getCapitalAccountAvailablePercentage";
import isHighCreditUtilization from "resources/line-of-credit/utils/isHighCreditUtilization";
import Banner from "ui/data-display/Banner";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import RightArrowButton from "ui/navigation/RightArrowButton";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import Text from "ui/typography/Text";

import CapitalAvailableTooltipContent from "./CapitalAvailableTooltipContent";
import styles from "./CaptialAvailableSection.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  showViewCardsButton?: boolean;
  showAvailableOutstandingBalance?: boolean;
};

const CapitalAvailableSection: FC<Props> = ({
  capitalAccountSummary,
  showViewCardsButton,
  showAvailableOutstandingBalance = false,
}) => {
  const barPercent = getCapitalAccountAvailablePercentage(capitalAccountSummary);

  const barRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const showHighCreditUtilizationWarning = isHighCreditUtilization(
    0 - capitalAccountSummary.runningBalance,
    capitalAccountSummary.details.limit
  );

  const hasChargeCard = capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly;

  return (
    <>
      {barRef.current && isHovered && (
        <Tooltip
          closeTooltip={() => setIsHovered(false)}
          openTooltip={() => setIsHovered(true)}
          referenceElement={barRef.current}
          rootElementId={"tooltip"}
        >
          <CapitalAvailableTooltipContent
            capitalLimit={capitalAccountSummary.details.limit}
            hasHighbeamCard={hasChargeCard}
            lineOfCreditUsed={0 - capitalAccountSummary.runningBalance}
            highbeamCardBalance={
              0 - (capitalAccountSummary.cardBalance + capitalAccountSummary.cardPending)
            }
          />
        </Tooltip>
      )}

      <div className={styles.header}>
        <DashboardPage.Section.HeaderHeading weight="medium">
          Available
        </DashboardPage.Section.HeaderHeading>
        {showViewCardsButton && (
          <Link to="/cards?tab=Highbeam">
            <RightArrowButton>View your cards</RightArrowButton>
          </Link>
        )}
      </div>

      <div className={styles.amountContainer}>
        <MoneyAmount
          size={28}
          weight="medium"
          centsTextSize={16}
          centsTextWeight="regular"
          cents={capitalAccountSummary.available}
        />

        <div className={styles.limitContainer}>
          <Text size={16} className={styles.limitFraction}>
            /
          </Text>
          <MoneyAmount size={16} cents={capitalAccountSummary.details.limit} withCents={false} />
        </div>
      </div>

      <div
        className={styles.barContainer}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        ref={barRef}
      >
        <div className={classNames(styles.barBorder)}>
          <div className={classNames(styles.barOutline)}>
            <div
              className={classNames(styles.bar, showHighCreditUtilizationWarning && styles.warning)}
              style={{ width: `${Math.max(barPercent, 1)}%` }}
            />
          </div>
        </div>
      </div>

      {showAvailableOutstandingBalance && (
        <div className={styles.balanceBreakdown}>
          <div className={styles.balanceBreakdownItem}>
            <div
              className={classNames(
                styles.availableBalance,
                showHighCreditUtilizationWarning && styles.warning
              )}
            />
            <div>
              <Text size={12}>Available</Text>
              <MoneyAmount size={12} weight="medium" cents={capitalAccountSummary.available} />
            </div>
          </div>
          <div className={styles.balanceBreakdownItem}>
            <div className={styles.outstandingBalance} />
            <div>
              <Text size={12}>Outstanding</Text>
              <MoneyAmount
                size={12}
                weight="medium"
                cents={capitalAccountSummary.details.limit - capitalAccountSummary.available}
              />
            </div>
          </div>
        </div>
      )}

      {showHighCreditUtilizationWarning && (
        <div className={styles.highCreditUtilizationBannerContainer}>
          <Banner
            color="yellow"
            icon={<Warning size={24} />}
            title="High capital usage"
            body={
              <Text size={14} weight="regular">
                High capital usage is not recommended and can result in the reduction of your
                capital limit. Consider repaying to reduce your usage.
              </Text>
            }
          />
        </div>
      )}
    </>
  );
};

export default CapitalAvailableSection;
