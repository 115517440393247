import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import { Simplify } from "type-fest";

import useRefreshQuery, { UseRefreshQueryOptions } from "./useRefreshQuery";

// NB(alex): Experimental but cautiously optimistic about this! It may be brittle / may not handle all cases yet so please use with caution.

/**
 * Generates a set of query hooks in a standardized format and preserves type-saftey and type inference of types.
 */
const makeQueryHooks = <TParams, TVariables, TQueryData, TError>(config: {
  name: string;
  useQueryVariables: (params: TParams) => TVariables;
  useQueryFnMaker: (variables: TVariables) => QueryFunction<TQueryData>;
}) => {
  const { name, useQueryVariables, useQueryFnMaker } = config;

  const useQueryKey = (params: TParams) => {
    const variables = useQueryVariables(params);
    return [name, variables];
  };

  const useQueryFn = (params: TParams) => {
    const variables = useQueryVariables(params);
    return useQueryFnMaker(variables);
  };

  return {
    name,
    useQueryVariables,
    useQueryKey,
    useQueryFn,
    useQuery<TSelect extends (data: TQueryData) => any = (data: TQueryData) => TQueryData>(
      params: Simplify<
        TParams &
          Omit<
            UseQueryOptions<
              TQueryData,
              TError,
              ReturnType<TSelect>,
              ReturnType<typeof useQueryKey>
            >,
            "queryKey" | "queryFn" | "select"
          > & {
            select?: TSelect;
          }
      >
    ): UseQueryResult<ReturnType<TSelect>, TError> {
      return useQuery({
        ...params,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
    },
    useSuspenseQuery<TSelect extends (data: TQueryData) => any = (data: TQueryData) => TQueryData>(
      params: TParams &
        Omit<
          UseSuspenseQueryOptions<
            TQueryData,
            TError,
            ReturnType<TSelect>,
            ReturnType<typeof useQueryKey>
          >,
          "queryKey" | "queryFn" | "select"
        > & {
          select?: TSelect;
        }
    ): UseSuspenseQueryResult<ReturnType<TSelect>, TError> {
      return useSuspenseQuery({
        ...params,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      });
    },
    // NB(alex): Duplicates `this.useSuspenseQuery` but wasn't sure how to extend it without losing types.
    useSuspenseQueryData<
      TSelect extends (data: TQueryData) => any = (data: TQueryData) => TQueryData,
    >(
      params: TParams &
        Omit<
          UseSuspenseQueryOptions<
            TQueryData,
            TError,
            ReturnType<TSelect>,
            ReturnType<typeof useQueryKey>
          >,
          "queryKey" | "queryFn" | "select"
        > & {
          select?: TSelect;
        }
    ): ReturnType<TSelect> {
      return useSuspenseQuery({
        ...params,
        queryKey: useQueryKey(params),
        queryFn: useQueryFn(params),
      }).data;
    },
    // Still need to figure out the correct pattern here...
    useRefreshQuery(params: TParams, options?: UseRefreshQueryOptions) {
      return useRefreshQuery(useQueryKey(params), options);
    },
    useRefreshQueries(options?: UseRefreshQueryOptions) {
      return useRefreshQuery([name], options);
    },
  };
};

export default makeQueryHooks;
