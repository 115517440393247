import useHasBothPartnerBankAccounts from "pages/cards/CardsPage/hooks/useHasBothPartnerBankAccounts";
import useUnitCoCustomer from "resources/unit-co-customer/queries/useUnitCoCustomer";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import cardsQueryHooks from "../queries/cardsQueryHooks";
import getHasChargeCards from "../utils/getHasChargeCards";

/**
 * Slack thread where we came up with this number: https://highbeamco.slack.com/archives/C04G6ENEJU8/p1712267207176619?thread_ts=1712250501.183099&cid=C04G6ENEJU8
 */

const useGetMaximumNumberOfCards = () => {
  const unitCoCustomer = useUnitCoCustomer();

  if (!unitCoCustomer) {
    throw new RequiredButNotFoundError();
  }

  const numUsers = unitCoCustomer.attributes.authorizedUsers.length + 1; // +1 for account owner

  // NB(alex): We will want another formula for "Standard tier" customers, which we can add to this hook.
  // Slack thread where we came up with this formula: https://highbeamco.slack.com/archives/C04G6ENEJU8/p1712267207176619?thread_ts=1712250501.183099&cid=C04G6ENEJU8
  const maximumNumberOfVirtualCards = 12 + 3 * numUsers;

  // NB(alex): Each user can have up to 1 physical credit card and 1 physical debit card. This can be overridden by contacting Unit directly. If we do this, we will have to fetch these values from the backend!
  const hasBothPartnerBankAccounts = useHasBothPartnerBankAccounts();
  const physicalCardCreationLimitMultiplier = hasBothPartnerBankAccounts ? 2 : 1;

  const maximumNumberOfPhysicalCreditCards = numUsers * physicalCardCreationLimitMultiplier;
  const maximumNumberOfPhysicalDebitCards = numUsers * physicalCardCreationLimitMultiplier;

  const hasChargeCards = cardsQueryHooks.useSuspenseQuery({
    status: "default",
    select: (cards) => getHasChargeCards(cards),
  });

  // NB(alex): This is temporary. Unit docs say they limit the maximum number of cards that are allowed to be created, but such a limit is not actually enforced.
  const maximumNumberOfPhysicalCards = hasChargeCards
    ? maximumNumberOfPhysicalDebitCards
    : maximumNumberOfPhysicalCreditCards + maximumNumberOfPhysicalDebitCards;

  return {
    maximumNumberOfVirtualCards: maximumNumberOfVirtualCards,
    maximumNumberOfPhysicalCards: maximumNumberOfPhysicalCards,
  };
};

export default useGetMaximumNumberOfCards;
