import CapitalAccountRep from "reps/CapitalAccountRep";

import useCapitalAccounts from "../queries/useCapitalAccounts";

export const checkIsCapitalAccountWithChargeCard = (
  capitalAccount: CapitalAccountRep.Complete
): capitalAccount is CapitalAccountRep.ChargeCardOnlyComplete => {
  return capitalAccount.type === CapitalAccountRep.Type.ChargeCardOnly;
};

const useCapitalAccountsWithChargeCard = () => {
  return useCapitalAccounts({
    select: (capitalAccounts) => capitalAccounts.filter(checkIsCapitalAccountWithChargeCard),
  });
};

export default useCapitalAccountsWithChargeCard;
