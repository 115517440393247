import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import cardsQueryHooks from "resources/cards/queries/cardsQueryHooks";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

export const useArchiveCardMutation = () => {
  const highbeamApi = useHighbeamApi();
  const refreshListCardsQueries = cardsQueryHooks.useRefreshQueries();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (cardId: string) => {
      await highbeamApi.card.close(cardId);
    },
    onSuccess: async () => {
      await refreshListCardsQueries();
      navigate("/cards");
      notify("success", "Card archived");
    },
    onError: (err) => {
      captureException(err);
      notify("warning", "There was an issue closing the card. Please try again.");
    },
  });
};
