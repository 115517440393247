import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { BASE_CARDS_QUERY_NAME } from "./cardsQueryHooks";

const useCardPinStatusQueryOptions = (cardId: string) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: [BASE_CARDS_QUERY_NAME, cardId, "pin-status"],
    queryFn: () => {
      return unitApi.cards.getPinStatus(cardId);
    },
  });
};

export default useCardPinStatusQueryOptions;
