import { zodResolver } from "@hookform/resolvers/zod";
import { X as XIcon } from "@phosphor-icons/react";
import classNames from "classnames";
import getSymbolFromCurrency from "currency-symbol-map";
import { debounce } from "debounce";
import { FC, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import AccountingAccountRep from "reps/AccountingAccountRep";
import BillLineItemRep from "reps/BillLineItemRep";
import BillSummaryRep from "reps/BillSummaryRep";
import useAccountingAccounts from "resources/accounting-accounts/queries/useAccountingAccounts";
import useDeleteBillLineItemMutation from "resources/bill-line-items/mutations/useDeleteBillLineItemMutation";
import useUpdateBillLineItemMutation from "resources/bill-line-items/mutations/useUpdateBillLineItemMutation";
import useBillLineItems from "resources/bill-line-items/queries/useBillLineItems";
import inferBillLineItemCurrencyFromBill from "resources/bill-line-items/utils/inferBillLineItemCurrency";
import useBill from "resources/bills/queries/useBill";
import Button from "ui/inputs/Button";
import TextInputV2 from "ui/inputs/TextInputV2";
import { useIsMobile } from "utils/device/useMediaQuery";
import { parseMoneyFloat } from "utils/money";
import { z } from "zod";

import styles from "./BillLineItemsTable.module.scss";

const AUTOSAVE_DEBOUNCE_DELAY = 750;

const billLineItemFormSchema = z.object({
  description: z.string().nonempty(),
  accountingAccountId: z.string().nullable(),
  amountAmount: z.string().refine((value) => {
    const parsedValue = parseMoneyFloat(value);
    if (isNaN(parsedValue)) {
      return false;
    }
    return parsedValue >= 0;
  }, "Please enter an amount greater than or equal to 0."),
});

type BillLineItemFormInputs = z.infer<typeof billLineItemFormSchema>;

type UseBillLineItemFormParams = {
  defaultValues: BillLineItemFormInputs;
};

const useBillLineItemForm = (params: UseBillLineItemFormParams) =>
  useForm<BillLineItemFormInputs>({
    resolver: zodResolver(billLineItemFormSchema),
    mode: "onChange",
    ...params,
  });

const makeBillLineItemUpdater = (
  billLineItem: BillLineItemRep.Complete,
  data: BillLineItemFormInputs
): BillLineItemRep.Updater => {
  const { description, accountingAccountId, amountAmount } = data;

  return {
    ...(description !== billLineItem.description && { description }),
    ...(accountingAccountId !== billLineItem.accountingAccountId && {
      accountingAccountId: accountingAccountId || null,
    }),
    ...(amountAmount !== billLineItem.amount.amount && {
      amount: { amount: amountAmount, currency: billLineItem.amount.currency },
    }),
  };
};

type BodyRowProps = {
  bill: BillSummaryRep.Complete;
  accountingAccounts: AccountingAccountRep.Complete[];
  billLineItem: BillLineItemRep.Complete;
  isMobile: boolean;
};

const BodyRow: FC<BodyRowProps> = ({ bill, accountingAccounts, billLineItem, isMobile }) => {
  const billLineItemId = billLineItem.id;
  const billId = bill.id;
  const currency = inferBillLineItemCurrencyFromBill(bill);

  const { mutate: updateBillLineItem } = useUpdateBillLineItemMutation(billLineItemId, billId);

  const { mutate: deleteBillLineItem, isPending: isDeletingBillLineItem } =
    useDeleteBillLineItemMutation(billLineItemId, billId);

  // NB(lev): Since we're rendering line items as rows in a table body, we're not
  // wrapping the inputs in an actual form. Neverthless, we're using the form abstraction
  // to handle input validation, state of the fields, etc.
  const {
    control,
    getValues,
    formState: { isValid },
  } = useBillLineItemForm({
    defaultValues: {
      description: billLineItem.description ?? "",
      accountingAccountId: billLineItem.accountingAccountId,
      amountAmount: billLineItem.amount.amount,
    },
  });

  const save = useCallback(async () => {
    if (!isValid) {
      return;
    }
    const updater = makeBillLineItemUpdater(billLineItem, getValues());
    if (Object.keys(updater).length === 0) {
      return;
    }
    updateBillLineItem(updater);
  }, [billLineItem, getValues, isValid, updateBillLineItem]);

  const onInputBlur = debounce(save, AUTOSAVE_DEBOUNCE_DELAY);

  return (
    <tr
      className={classNames(
        styles.bodyRowContainer,
        isDeletingBillLineItem && styles["bodyRowContainer-deleting"]
      )}
    >
      <td>
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <TextInputV2
              variant={isMobile ? "default" : "minimal"}
              label="Description"
              showErrorOutline={Boolean(fieldState.error)}
              {...field}
              onBlur={() => {
                field.onBlur();
                onInputBlur();
              }}
            />
          )}
        />
      </td>
      <td>
        <Controller
          name="accountingAccountId"
          control={control}
          render={({ field }) => (
            <select
              {...field}
              value={field.value ?? ""}
              onChange={(e) => {
                field.onChange(e);
                save();
              }}
              onBlur={() => {
                field.onBlur();
                onInputBlur();
              }}
            >
              <option value=""></option>
              {accountingAccounts.map((accountingAccount) => (
                <option key={accountingAccount.id} value={accountingAccount.id}>
                  {accountingAccount.name}
                </option>
              ))}
            </select>
          )}
        />
      </td>
      <td>
        <Controller
          name="amountAmount"
          control={control}
          render={({ field, fieldState }) => (
            <TextInputV2
              variant={isMobile ? "default" : "minimal"}
              label="Amount"
              showErrorOutline={Boolean(fieldState.error)}
              startAdornment={getSymbolFromCurrency(currency)}
              {...field}
              onBlur={() => {
                field.onBlur();
                onInputBlur();
              }}
            />
          )}
        />
      </td>
      <td>
        <div className={styles.bodyRowActions}>
          <Button
            aria-label="Remove"
            isSquare
            variant="ghost"
            isLoading={isDeletingBillLineItem}
            onClick={() => deleteBillLineItem()}
          >
            <XIcon size={16} />
          </Button>
        </div>
      </td>
    </tr>
  );
};

type Props = {
  billId: string;
};

const BillLineItemsTable: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const billLineItems = useBillLineItems(billId);
  const accountingAccounts = useAccountingAccounts();
  const isMobile = useIsMobile();

  return (
    <table className={styles.container}>
      <thead className={styles.head}>
        <tr>
          <th>Description</th>
          <th>Account</th>
          <th>Amount</th>
          <th>
            <span className="sr-only">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {billLineItems.map((billLineItem) => (
          <BodyRow
            key={billLineItem.id}
            bill={bill}
            accountingAccounts={accountingAccounts}
            billLineItem={billLineItem}
            isMobile={isMobile}
          />
        ))}
      </tbody>
    </table>
  );
};

export default BillLineItemsTable;
