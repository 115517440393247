import { useQuery } from "@tanstack/react-query";
import useUnitCoTaxFormsQueryOptions from "resources/account-tax-forms/useUnitCoTaxFormsQueryOptions";
import useBusiness from "resources/business/queries/useBusiness";
import Shimmer from "ui/feedback/ShimmerV2";
import Text from "ui/typography/Text";
import { pluralize } from "utils/string";

import styles from "../AccountsStatements.module.scss";

type Props = {
  accountId?: string;
};

const TaxFormsCount: React.FC<Props> = ({ accountId }) => {
  const { unitCoCustomerId: customerId } = useBusiness();
  const { data, isLoading } = useQuery(
    useUnitCoTaxFormsQueryOptions({ customerId: customerId ?? undefined, accountId })
  );

  if (isLoading) {
    return <Shimmer width={128} />;
  }

  const taxForms = data?.data ?? [];

  if (taxForms.length === 0) {
    return null;
  }

  return (
    <Text size={14} className={styles.count}>
      {pluralize(taxForms.length, "document")} available.
    </Text>
  );
};

export default TaxFormsCount;
