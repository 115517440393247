import { captureException } from "@sentry/react";
import activateCardIcon from "assets/activate-card-icon.svg";
import successIcon from "assets/success.svg";
import env from "env";
import { useEffect, useState } from "react";
import cardsQueryHooks from "resources/cards/queries/cardsQueryHooks";
import useGetUnitCoSensitiveTokenWithMfa from "resources/unit-co-customer-token/hooks/useGetUnitCoSensitiveTokenWithMfa";
import colors from "styles/colors";
import { notify } from "ui/feedback/Toast";
import { getBaseVgsCss } from "utils/cards";
import useImportScript from "utils/customHooks/useImportScript";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

// JS library imported in hook below
declare const VGSCollect: any;

const VGS_INPUT_CSS = {
  ...getBaseVgsCss({ monospace: true, numeric: true }),
  "letter-spacing": "0.02em",
  color: colors.grey[500],
};

export enum Step {
  ACTIVATE,
  SUCCESS,
}

type StepInfo = {
  title: string;
  buttonText: string;
  iconURL: string;
  showCancel: boolean;
};

export const stepInfoMap: Record<Step, StepInfo> = {
  [Step.ACTIVATE]: {
    title: "Activate your card",
    buttonText: "Activate card",
    iconURL: activateCardIcon,
    showCancel: true,
  },
  [Step.SUCCESS]: {
    title: "Card activated",
    buttonText: "Close",
    iconURL: successIcon,
    showCancel: false,
  },
};

export const useActivateCardModal = (onClose: () => void, cardId: string) => {
  const { segmentTrack } = useSegment();
  const [step, setStep] = useState(Step.ACTIVATE);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [vgsForm, setVgsForm] = useState<any>(null);
  const refreshCardsQueries = cardsQueryHooks.useRefreshQueries();

  const getUnitCoSensitiveTokenWithMfa = useGetUnitCoSensitiveTokenWithMfa({
    scopes: ["CARDS_SENSITIVE_WRITE"],
  });

  const vgsLoaded = useImportScript(
    `https://js.verygoodvault.com/vgs-collect/2.9.0/vgs-collect.js`
  );

  const activateCard = async (vgsForm: any) => {
    if (!vgsForm) {
      return;
    }
    setIsLoading(true);
    setHasError(false);
    try {
      const sensitiveToken = await getUnitCoSensitiveTokenWithMfa();

      vgsForm.submit(
        `/cards/${cardId}/activate`,
        {
          // This converts the dot-separated field name strings into a JSON object
          mapDotToObject: "true",
          headers: {
            "Content-Type": "application/vnd.api+json",
            Authorization: `Bearer ${sensitiveToken}`,
          },
        },
        (status: any, _data: any) => {
          if (status === 200) {
            // hacky fix for VGS postMessage error, see PR#996
            setTimeout(() => setStep(Step.SUCCESS), 100);
            segmentTrack(SEGMENT_EVENTS.CARD_ACTIVATED, {
              cardId,
            });
          } else {
            notify("error", "Something went wrong activating your card.");
            captureException(
              new Error(`VGS returned with status ${status} and data ${JSON.stringify(_data)}`)
            );
          }
        },
        (errors: any) => {
          if (errors) {
            setHasError(true);
          }
        }
      );
    } catch (e) {
      notify("error", "Something went wrong activating your card.");
      captureException(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (vgsLoaded && !vgsForm) {
      const form = VGSCollect.create(env.UNIT_VGS_VAULT_ID, env.UNIT_VGS_ENVIRONMENT, () => {});
      form.on("enterPress", () => activateCard(form));
      const ccv = form.field("#cvv", {
        type: "card-security-code",
        name: "data.attributes.cvv2",
        placeholder: "CVV",
        maxLength: 3,
        validations: ["required", "validCardSecurityCode"],
        css: VGS_INPUT_CSS,
      });
      const expiryDate = form.field("#expiryDate", {
        type: "card-expiration-date",
        name: "data.attributes.expirationDate",
        placeholder: "Expiry (MM/YY)",
        validations: ["required", "validCardExpirationDate"],
        yearLength: "2",
        serializers: [form.SERIALIZERS.replace("(\\d{2})\\s\\/\\s(\\d{2})", "20$2-$1")],
        css: VGS_INPUT_CSS,
      });
      Promise.all([ccv.promise, expiryDate.promise]).then(() => {
        setVgsForm(form);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vgsForm, vgsLoaded]);

  const handleNext = async () => {
    switch (step) {
      case Step.ACTIVATE:
        activateCard(vgsForm);
        break;
      case Step.SUCCESS: {
        onClose();
        refreshCardsQueries();
        handleReset();
        break;
      }
    }
  };
  const handleReset = () => {
    setStep(Step.ACTIVATE);
    setIsLoading(false);
    setHasError(false);
    setVgsForm(null);
  };

  return {
    step,
    handleNext,
    handleReset,
    isLoading,
    hasError,
  };
};
